<template>
  <div class="invite">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />

      <span>个人中心</span>
    </div>
    <div class="body">
      <div class="flex-row">
        <span> 头像 </span>
        <!-- <img @click="back" :src="userinfo.avatar" class="avatar" /> -->
        <van-uploader v-model="fileList" multiple :max-count="1" :after-read="afterRead" class="arrow_img" />
      </div>
      <div class="flex-row">
        <span> 昵称 </span>
        <input placeholder="请输入昵称" focus type="text" class="input" v-model="userinfo.nickname" />
      </div>
    </div>
    <div class="button">
      <van-button class="submit_button" type="primary" @click="submit">修改</van-button>
    </div>
  </div>
</template>
    
<script>
import { homeApi } from '@/api/index';
export default {
  data() {
    return {
      userinfo: {
        nickname: '',
      },
      fileList: [
        {
          url: '',
          status: 'uploading',
          message: '加载中...',
        },
      ],
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    getUserInfo() {
      homeApi.getUserInfo({}).then(res => {
        if (res.code == 200) {
          this.userinfo = res.data;
          this.fileList=[]
          this.fileList.push({ url: this.userinfo.avatar });
        }
      });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    submit() {},
  },
};
</script>
    
<style lang="less" scoped>
.invite {
  min-height: 100vh;
  padding: 66px 16px 0;
  .head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    padding: 14px 0;
    background: #fff;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
  .body {
    color: #000;
    font-size: 14px;
    padding: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.3);

    .flex-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      padding: 10px 0;
    }
    .avatar {
      width: 68px;
      height: 68px;
      border-radius: 50%;
    }
    .input {
      text-align: right;
      border: none;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  .button {
    display: flex;
    justify-content: center;
    .submit_button {
      color: rgba(#fff, 0.95);
      background: #2f46d4;
      border: 0;
      border-radius: 12px;
      padding: 12px 0;
      width: 100%;
      margin-top: 30px;
      letter-spacing: 1.5px;
    }
  }
}
</style>