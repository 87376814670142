<template>
  <div class="invite">
    <div class="head">
      <img @click="back" src="@/assets/images/back.png" class="arrow_img" />
      <span>我的薪资</span>
    </div>
    <div class="table">
      <div class="th flex">
        <span class="text"> 月份 </span>
        <span class="text"> 工资总额（U） </span>
        <span class="text"> 状态 </span>
      </div>
      <div class="tbody">
        <template v-if="total > 0">
          <van-list v-model="loading" :finished="finished" :finished-text="finishText" @load="onLoad" class="list_box" ref="vanList">
            <div class="flex item" v-for="(item, index) in list" :key="index" @click="showDetail(item)">
              <span class="text"> {{ dateFormatFn(item.create_time) }} </span>
              <span class="text"> {{ Number(item.base) + Number(item.jobs) + Number(item.work_full_hours) + Number(item.telephone) + Number(item.divvy) + Number(item.bonus) || 0 }}</span>
              <span class="text"> {{ item.status == 0 ? '待发放' : item.status == 1 ? '已发放' : '未知' }} </span>
            </div>
          </van-list>
        </template>

        <template v-else>
          <div class="nodata item">
            <span> 暂无数据 </span>
          </div>
        </template>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="title">
            <span>{{ dateFormatFn(detail.create_time) }}</span>
          </div>
          <div class="row">
            <span class="key">底薪</span>
            <span>{{ detail.base }}</span>
          </div>
          <div class="row">
            <span class="key">岗位补贴</span>
            <span>{{ detail.jobs }}</span>
          </div>
          <div class="row">
            <span class="key">满勤补贴</span>
            <span>{{ detail.work_full_hours }}</span>
          </div>
          <div class="row">
            <span class="key">话费补贴</span>
            <span>{{ detail.telephone }}</span>
          </div>
          <div class="row">
            <span class="key">分红</span>
            <span>{{ detail.divvy }}</span>
          </div>
          <div class="row">
            <span class="key">奖金</span>
            <span>{{ detail.bonus }}</span>
          </div>
          <div class="row">
            <span class="key">总计</span>
            <span>{{ Number(detail.base) + Number(detail.jobs) + Number(detail.work_full_hours) + Number(detail.telephone) + Number(detail.divvy) + Number(detail.bonus) || 0 }}</span>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { homeApi } from '@/api/index';
import { dateFormat, statusFormat } from '@/utils/formatTool.js';
export default {
  data() {
    return {
      list: [],
      page: 0,
      loading: true,
      finished: false,
      total: 1,
      realList: 0,
      finishText: '加载更多',
      show: false,
      detail: {},
    };
  },
  mounted() {
    this.onLoad();
    window.addEventListener('scroll', this.scrollBottom);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom);
  },
  methods: {
    scrollBottom() {
      //加载更多
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //滚动条到底部的条件
      console.log(scrollTop, 'scrollTop');
      console.log(windowHeight, 'windowHeight');
      console.log(scrollHeight, 'scrollHeight');
      if (scrollTop + windowHeight >= scrollHeight && scrollTop > 0) {
        //写后台加载数据的函数
        console.log('滚动到底部啦');
        if (this.finished) {
          this.loading = true; // 关闭加载状态
          this.finished = false; // 加载开始
          this.finishText = '加载更多';
          this.onLoad();
        }
      } else {
        // this.loading = false; // 关闭加载状态
      }
    },
    showDetail(item) {
      this.show = true;
      this.detail = item;
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM');
    },
    statusFormatFn(status) {
      return statusFormat(status);
    },
    //返回上一页
    back() {
      this.$router.back();
    },
    getHash(hash) {
      if (hash) {
        return '0x...' + hash.substring(hash.length - 12, hash.length);
      } else {
        return '';
      }
    },
    check(e) {
      this.$refs.vanList.check();
    },
    getSalaryList() {
      let params = {
        page: this.page,
        query: {},
      };
      homeApi.salaryList(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          let rows = res.data.rows;
          // 如果返回的数组是空或数组长度是0
          if (rows == null || rows.length === 0) {
            this.loading = false; // 关闭加载状态
            this.finished = true; // 加载结束
            this.finishText = '没有更多了';
            if (this.list.length <= 0) {
              this.total = 0;
            }
            return;
          }
          this.loading = false; // 关闭加载状态
          // this.loading = true; // 开启加载状态
          this.finishText = '上滑加载更多';
          this.finished = true;
          this.total = res.data.total; //数据总条数
          this.list = this.list.concat(rows); //合并数组
          console.log(this.list);

          // 如果合并之后的数组长度大于返回的数据总条数
          if (this.list.length >= this.total) {
            this.finished = true; // 加载结束
            this.finishText = '没有更多了';
          }
        } else {
          this.total = 0;
          this.loading = false; // 关闭加载状态
          this.finished = true; // 加载结束
          this.finishText = '没有更多了';
        }
      });
    },

    onLoad() {
      this.page += 1;
      this.getSalaryList();
    },
  },
};
</script>

<style lang="less" scoped>
.invite {
  min-height: calc(100vh + 50px);
  padding-top: 50px;
  .head {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(#000, 0.8);
    letter-spacing: 1.5px;
    font-weight: 550;
    background: #fff;
    padding: 14px 0;
    .arrow_img {
      position: absolute;
      left: 20px;
      width: 10px;
      height: 16px;
    }
  }
  .table {
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 16px;
    margin: 16px;
    color: #000;
    font-size: 13px;
    padding: 16px;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .text {
      display: block;
      width: 33.3%;
      text-align: center;
    }
    .th {
      font-weight: 550;
      color: rgba(#000, 0.86);
      padding-bottom: 16px;
    }
    .tbody {
      font-size: 12px;
      color: rgba(83, 89, 122, 1);

      .item {
        padding: 12px 0;
        border-top: 1px solid rgba(232, 240, 247, 1);
      }
      .nodata {
        text-align: center;
        line-height: 380%;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    color: #000;
    width: 80%;
    padding: 16px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.1);

    .title {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 6px;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding: 12px 0;
      border-bottom: 1px solid rgba(232, 240, 247, 1);

      .key {
        color: rgba(83, 89, 122, 1);
      }
    }
    .row:last-child {
      border: 0;
    }
  }
}
</style>