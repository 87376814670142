<template>
  <div class="assets">
    <div class="head">
      <!-- <img @click="back" src="@/assets/images/back.png" class="arrow_img" /> -->
      <span>我的资产</span>
    </div>
    <div class="">
      <div class="total_box">
        <span class="total">{{ Math.floor(assets * 100) / 100 || 0 }}</span>
        <span class="text">用户总资产</span>
      </div>
      <div class="balance_box">
        <div class="balance_li" @click="navigation('usdtWithdraw', '')">
          <img src="@/assets/images/wallet/icon1.png" alt="" class="icon" />
          <span>USDT提现</span>
        </div>
        <!-- <div class="balance_li" @click="navigation('etcManage', '')">
          <img src="@/assets/images/wallet/icon2.png" alt="" class="icon" />
          <span>ETC管理</span>
        </div> -->
        <div class="balance_li" @click="navigation('smhManage', '')">
          <img src="@/assets/images/wallet/icon2.png" alt="" class="icon" />
          <span>SMH管理</span>
        </div>
        <div class="balance_li" @click="navigation('arManage', '')">
          <img src="@/assets/images/wallet/icon2.png" alt="" class="icon" />
          <span>AR管理</span>
        </div>
      </div>
      <!-- <div class="balance_box">
        <div class="balance_li" @click="navigation('etcWithdraw', '')">
          <img src="@/assets/images/wallet/icon1.png" alt="" class="icon" />
          <span>ETC提现</span>
        </div>
        <div class="balance_li" @click="navigation('transfer', '')">
          <img src="@/assets/images/wallet/icon2.png" alt="" class="icon" />
          <span>SMH管理</span>
        </div>
      </div> -->

      <div class="bare" v-if="total > 1">
        <span>暂无数据</span>
      </div>
      <div v-else>
        <div class="list">
          <div
            v-for="(item, index) in coinList"
            :key="index"
            :style="{ 'border-top': index != 0 ? ' 1px solid rgba(232, 240, 247, 1)' : '' }"
            class="li"
            @click="navigation('assetDetails', { coin_type: item.coin_key, coin_name: item.coin_name })"
          >
            <div class="li_left">
              <img :src="require('@/assets/images/wallet/' + item.coin_key + '.png')" class="li_img" />
              <span>{{ item.coin_name }}</span>
            </div>
            <span class="num">{{ Math.floor(item.amount * 100) / 100 || 0 }}</span>
          </div>
        </div>
      </div>
    </div>
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import BottomNavigation from '@/components/BottomNavigation.vue';
import { homeApi } from '@/api/index';
import { dateFormat } from '@/utils/formatTool.js';
export default {
  components: {
    BottomNavigation,
  },
  data() {
    return {
      page: 0,
      loading: false,
      finished: false,
      total: 1,

      assets: 0,
      coinList: [
        // {
        //   coin_name: 'USDT',
        //   amount: '0',
        // },
        // {
        //   coin_name: '算力',
        //   amount: '0',
        // },
        // {
        //   coin_name: '能量值',
        //   amount: '0',
        // },
      ],
      showPopover: false,
      typeText: '全部',
      actionType: -1, //-1:全部 0:未知 1:租赁 2:直推奖励 3:团队的算力奖励 4:团队的平级算力奖励
    };
  },
  mounted() {
    this.actionGet();
  },
  methods: {
    //返回上一页
    back() {
      this.$router.back();
    },
    navigation(name, query) {
      console.log(query);
      if (query.coin_type == 'server') {
        this.$router.push({ name: 'orders' });
      } else {
        this.$router.push({ name, query });
      }
    },
    dateFormatFn(date) {
      return dateFormat(new Date(date * 1000), 'yyyy-MM-dd hh:mm:ss');
    },

    actionGet() {
      homeApi.assets().then(res => {
        if (res.code == 200) {
          this.assets = res.data.assets;
          this.coinList = res.data.coin_list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(#000, 0.8);
  letter-spacing: 1.5px;
  font-weight: 550;
  background: #fff;
  padding: 14px 0;
  .arrow_img {
    position: absolute;
    left: 20px;
    width: 10px;
    height: 16px;
    // transform: translate(0, -50%);
  }
}
.assets {
  min-height: 100vh;
  padding: 66px 16px 100px;
  .total_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    color: #fff;
    height: 150px;
    text-align: center;
    border-radius: 16px;
    // padding: 20px;
    background: url('~@/assets/images/wallet/bg.png') no-repeat center;
    background-size: 120% 120%;

    .total {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .text {
      color: rgba(#fff, 0.8);
    }
  }
  .balance_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    .balance_li {
      color: #000;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 32%;
      font-size: 14px;
      border-radius: 16px;
      padding: 16px 0;
      box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
      background-color: rgba(255, 255, 255, 0.3);

      .icon {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
    }
  }

  .bare {
    text-align: center;
    font-size: 14px;
    padding: 60px 14px;
    color: #888;
  }
  .list {
    border-radius: 16px;
    padding: 0 16px;
    margin-top: 16px;
    box-shadow: 4px 4px 15px 0px rgba(180, 212, 212, 0.59);
    background-color: rgba(255, 255, 255, 0.3);
    .li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      padding: 14px 0;

      .li_left {
        display: flex;
        align-items: center;
        .li_img {
          display: block;
          width: 32px;
          height: 32px;
          margin-right: 6px;
          border-radius: 50%;
        }
      }

      .num {
        font-size: 16px;
        font-weight: 550;
      }
    }
  }
}
</style>