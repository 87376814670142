export const lang = {
  swap1: 'The output is a valuation, you will get at least {inputOutMin} {symbol}, or the transaction will be withdrawn',
  swap2: 'The input is a valuation, you will sell up to {inputInMax} {symbol}, or the trade will be withdrawn',
  swap3: 'The output is an estimate. Your trade will be withdrawn if the price changes by more than {slippage}%',
  swap4: 'The output is an estimate. Your trade will be withdrawn if the price changes by more than {slippage}%',
  swap5: 'Confirm swap',
  swap6: 'Waiting for confirmation',
  swap7: 'Swapping',
  swap8: 'Swap to',
  swap9: 'Confirm this transaction in your wallet',
  swap10: 'Submitted transaction',
  swap11: 'View on BscScan',
  swap12: 'Add Swan-LP to Metamsak',
  swap13: 'Transaction rejected',
  swap14: 'close',
  swap15: 'Removing',
  swap16: 'and',
  swap17: 'Supplying',
  swap18: 'Exchange',
  swap19: 'liquidity',
  swap20: 'from',
  swap21: 'estimate',
  swap22: 'balance',
  swap23: 'max',
  swap24: 'Select token',
  swap25: 'to',
  swap26: 'Connect wallet',
  swap27: 'Insufficient liquidity for this transaction',
  swap28: 'input quantity',
  swap29: 'Insufficient balance',
  swap30: 'Select token',
  swap31: 'authorization',
  swap32: 'wrapper',
  swap33: 'unpack',
  swap34: 'Price influence is too high',
  swap35: 'Swap',
  swap36: 'price',
  swap37: 'Slippage tolerance',
  swap38: 'Price Impact',
  swap39: 'routing',
  swap40: 'Minimum gain',
  swap41: 'liquidity provider fee',
  swap42: 'Transaction receipt',
  swap43: 'flash pair',
  swap44: 'you will get',
  swap45: 'Maximum amount to sell',
  swap46: 'The price impact of this swap is at least 10%, please confirm this swap',
  swap47: 'Confirm',
  swap48: 'Cancel',
  swap49: 'Recent transaction',
  swap50: 'Clear all',
  swap51: 'No transaction recently',
  swap52: 'your liquidity',
  swap53: 'Pooled',
  swap54: 'Add',
  swap55: 'remove',
  swap56: 'Increase liquidity',
  swap57: 'Don t see the pool you joined? ',
  swap58: 'import it',
  swap59: 'If you stake XXX tokens on the farm, unstake them here to see them',
  swap60: 'You are the first liquidity provider. ',
  swap61: 'The ratio of tokens you add will determine the initial price of the liquidity pool. ',
  swap62: 'If you are satisfied with the exchange rate, click Supply to check it. ',
  swap63: 'input',
  swap64: 'Exchange rate and liquidity pool shares',
  swap65: 'Shares in the liquidity pool',
  swap66: 'Supply',
  swap67: 'Receive',
  swap68: 'amount',
  swap69: 'you will get',
  swap70: 'LP tokens in your wallet',
  swap71: 'Remove liquidity to withdraw tokens',
  swap72: 'Import pool',
  swap73: 'Choose a token to find your liquidity. ',
  swap74: 'You have not provided liquidity in this liquidity pool. ',
  swap75: 'Deposited',
  swap76: 'Exchange Rate',
  swap77: 'Confirm supply',
  swap78: 'Incinerated',
  swap79: 'Asset change',
  swap80: 'Withdrawal bill',
  swap81: 'withdrawal amount',
  swap82: 'handling fee',
  swap83: 'Actual arrival',
  swap84: 'passed',
  swap85: 'rejected',
  swap86: 'buy',
  swap87: 'sell',
  swap88: 'Order',
  swap89: 'Required release',
  swap90: 'unit price',
  swap91: 'Manage tokens',
  swap92: 'Import tokens',
  swap93: 'Search name or paste address',
  swap94: 'import',
  swap95: 'Manage Tokens',
  swap96: 'custom token',
  swap97: 'Home',
  swap98: 'transaction',
  swap99: 'info',
  swap100: 'Community',
  swap101: 'My',
  swap102: 'Not connected',
  swap103: 'Applying',
  swap104: 'Enter',
  swap105: 'Market display',
  swap106: 'currency',
  swap107: 'Rise and fall',
  swap108: 'The Butterfly Tribe is a group of communities with common interests, cognition, and values ​​that unite to interact, communicate, collaborate, and infect a new platform of trust and value linking. The emotional connection and trust between them generate new tribal communities. The advent of the blockchain era has enabled the community to exert unimaginable power. Through more efficient connection and value exchange between communities and community organizations, a new efficient and efficient operating economy is formed. ',
  swap109: 'Not yet open',
  swap110: 'Butterfly Community',
  swap111: 'SER & SES dual currency linkage · Reincarnation is about to open equity subscription',
  swap112: 'Community Announcement',
  swap113: 'Contact',
  swap114: 'message',
  swap115: 'circle',
  swap116: 'Search contacts',
  swap117: 'No contact yet',
  swap118: 'No data',
  swap119: 'Block this user',
  swap120: 'Report this content',
  swap121: 'Fast forward',
  swap122: 'reference',
  swap123: 'Share',
  swap124: 'new friend',
  swap125: 'group',
  swap126: 'channel',
  swap127: 'Nearby people and groups',
  swap128: 'published',
  swap129: 'Please enter the post',
  swap130: 'Only JPG, BMP, PNG, GIF are accepted',
  swap131: 'Uploading...',
  swap132: 'Upload successful',
  swap133: 'Upload failed',
  swap134: 'Please enter the content',
  swap135: 'Please add a picture',
  swap136: 'Table cloth successfully',
  swap137: 'depth',
  swap138: 'Newsletter',
  swap139: 'Swap bill',
  swap140: 'Insufficient current balance',
  swap141: 'Please select a currency',
  swap142: 'Swap successful',
  swap143: 'earned',
  swap144: 'Annualized interest rate',
  swap145: 'reap',
  swap146: 'Pledge',
  swap147: 'in progress',
  swap148: 'Ended',
  swap149: 'I participated',
  swap150: 'Pledge lp token',
  swap151: 'Redeem lp tokens',
  swap152: 'Redeem',
  swap153: 'really',
  swap154: 'Share link',
  swap155: 'Newbie profile',
  swap156: 'more',
  swap157: 'Post image material',
  swap158: 'Common tools',
  swap159: 'FAQ',
  swap160: 'Market Mechanism',
  swap161: 'Development plan',
  swap162: 'Previous',
  swap163: 'Next',
  swap164: 'Hairband material',
  swap165: 'Hairband poster',
  swap166: 'Hairband video',
  swap167: 'Hair circle copy',
  swap168: 'Market activity',
  swap169: 'Holiday wishes',
  swap170: 'Friend Invite',
  swap171: 'Project introduction',
  swap172: 'all',
  swap173: 'transfer into',
  swap174: 'swap out',
  swap175: 'Transfer',
  swap176: 'Receipt',
  swap177: 'Please enter the transfer amount',
  swap178: 'Please enter the recipient s address',
  swap179: 'Transfer successful',
  swap180: 'Receiver',
  swap181: 'quantity',
  swap182: 'Swapable',
  swap183: 'Please choose',
  swap184: 'Please enter the quantity',
  swap185: 'Maximum transfer',
  swap186: 'Minimum transfer',
  swap187: 'Transaction value deduction',
  swap188: 'Fees deduction',
  swap189: 'Please enter the nickname to be modified',
  swap190: 'Save',
  swap191: 'Please upload a picture first',
  swap192: 'Modified successfully',
  swap193: 'I want feedback',
  swap194: 'Reply',
  swap195: 'Feedback title',
  swap196: 'Title',
  swap197: 'Please fill in the title',
  swap198: 'feedback content',
  swap199: 'Please fill in the content',
  swap200: 'attachment',
  swap201: 'commit',
  swap202: 'No reply yet',
  swap203: 'Submitted successfully',
  swap204: 'Your question',
  swap205: 'What problems do you encounter in the process of using the app, or any suggestions for the platform can be written here',
  swap206: 'Please fill in the reply content',
  swap207: 'Butterfly Academy',
  swap208: 'Invite friends',
  swap209: 'Star Center',
  swap210: 'Asset',
  swap211: 'available',
  swap212: 'frozen',
  swap213: 'Contribution value',
  swap214: 'View mining pool',
  swap215: 'Asset details',
  swap216: 'Please link the account first',
  swap217: 'Go to invite',
  swap218: 'My Referrer',
  swap219: 'No superior',
  swap220: 'My recommendation',
  swap221: 'No recommendation yet',
  swap222: 'Copy succeeded',
  swap223: 'Copy is not supported, this browser does not support automatic copy',
  swap224: 'Swap',
  swap225: 'holding pool',
  swap226: 'Receive',
  swap227: 'reading',
  swap228: 'Growth Zone',
  swap229: 'Gift area',
  swap230: 'Starting center',
  swap231: 'The Butterfly Tribe is a group of communities with common interests, cognition, and values ​​that unite to interact, communicate, collaborate, and infect a new platform of trust and value links. The emotional connection and trust between them generate new tribal communities. The advent of the blockchain era has enabled the community to exert unimaginable power. Through more efficient connection and value exchange between communities and community organizations, a new efficient and efficient operating economy is formed. ',
  swap232: 'Currently completed progress percentage',
  swap233: 'will exceed 100%',
  swap234: 'Always need to donate',
  swap235: 'You have donated',
  swap236: 'Description',
  swap237: 'The recruitment information for the leaders of xxxx is provided and responsible by xxxx',
  swap238: 'This recruitment can be carried out in excess, and it will be assessed by the xxxx community. The leader seat that fails to pass will be automatically returned to the donated funds,',
  swap239: 'member',
  swap240: 'joint partner',
  swap241: 'Shareholder partner',
  swap242: 'Congratulations, you got it',
  swap243: 'list',
  swap244: 'in progress',
  swap245: 'Completed',
  swap246: 'Number of times',
  swap247: 'Total capacity',
  swap248: 'Number pool',
  swap249: 'Number gift',
  swap250: 'Total reward',
  swap251: 'consumption',
  swap252: 'Completion times',
  swap253: 'rewarded',
  swap254: 'Remaining times',
  swap255: 'Deposit',
  swap256: 'Withdrawal',
  swap257: 'Copy',
  swap258: 'Please enter the recharge amount',
  swap259: 'Please enter the withdrawal amount',
  swap260: 'Platform balance',
  swap261: 'Deposit successful',
  swap262: 'Transfer failed',
  swap263: 'Withdrawal amount unavailable balance',
  swap264: 'Currently unavailable for withdrawal',
  swap265: 'Exceeds the current maximum amount of coins that can be withdrawn',
  swap266: 'Withdrawal succeeded',
  swap267: 'Total Assets',
  swap268: 'token',
  swap269: 'Deal',
  swap270: 'Transaction rate',
  swap271: 'limit',
  swap272: 'go to buy',
  swap273: 'go to sell',
  swap274: 'Please enter the purchase quantity',
  swap275: 'The purchase quantity must be within the limit',
  swap276: 'Exceeded balance',
  swap277: 'Purchase successful',
  swap278: 'Successful sale',
  swap279: 'My order',
  swap280: 'Available balance',
  swap281: 'Please enter the sale quantity',
  swap282: 'Remaining tradable',
  swap283: 'Buy now',
  swap284: 'Sell now',
  swap285: 'My Post',
  swap286: 'Post order',
  swap287: 'release type',
  swap288: 'buy',
  swap289: 'sale',
  swap290: 'Payment method',
  swap291: 'Please select a payment method',
  swap292: 'Please enter the unit price',
  swap293: 'Number of releases',
  swap294: 'Please enter the number of releases',
  swap295: 'Select currency',
  swap296: 'Maximum limit',
  swap297: 'Please enter the maximum limit',
  swap298: 'Minimum limit',
  swap299: 'Please enter a minimum amount',
  swap300: 'release',
  swap301: 'Enter the maximum price should be',
  swap302: 'Minimum price should be',
  swap303: 'The maximum number of inputs should be',
  swap304: 'Minimum quantity should be',
  swap305: 'buy',
  swap306: 'sell',
  swap307: 'Order number',
  swap308: 'Cancel order',
  swap309: 'Undo succeeded',
  swap310: 'Unit price is out of range',
  swap311: 'Number not in range',
  swap312: 'Please select a currency to publish',
  swap313: 'Please select the minimum amount',
  swap314: 'Please select the maximum limit',
  swap315: 'The minimum limit cannot be greater than the maximum limit',
  swap316: 'The maximum limit cannot exceed the number of releases',
  swap317: 'The purchase price cannot exceed the balance',
  swap318: 'The release quantity cannot exceed the balance',
  swap319: 'Published successfully',
  swap320: 'Withdrawal',
  swap321: 'You have joined',
  swap322: 'join',
  swap323: 'Please login first',
  swap324: 'Authorization failed',
  swap325: 'Join successfully',
  swap326: 'Actual exchange amount',
  swap327: 'Number received',
  swap328: 'Please enter the exchange amount',
  swap329: 'Please add an inviter first',
  swap330: 'Upgrade',
  swap331: 'SER is an ecological token based on DAO+ iGaming 3.0 in the DEFI field, the sub-currency SES. Through the new dual-currency linkage market promotion plan, it provides users with an entertaining experience while ensuring the continuous and stable growth of value. Nirvana from ashes, reincarnation, endless life, value immortal, SER rights recognition is starting! ',
  swap332: 'My reward',
  swap333: 'Recommended list',
  swap334: 'Address',
  swap335: 'OK will',
  swap336: 'Transfer to asset',
  swap337: 'Mining Pool Account',
  swap338: 'Wallet account',
  swap339: 'Contribution value balance',
  swap340: 'Consumption Contribution Value',
  swap341: 'Confirm swap',
  swap342: 'Increase contribution value',
  swap343: 'Transfer successfully',
  swap344: 'Insufficient contribution value',
  swap345: 'Transfer successfully',
  swap346: 'There is currently no available balance',
  swap347: 'Exceeded maximum holding',
  swap348: 'Coming soon',
  swap349: 'Payable',
  swap350: 'will get',
  swap351: 'Invitation bonus program',
  swap352: 'Invite users to help the construction of Nebula infrastructure, and become the co constructors of Nebula, you can obtain multiple benefits.',
  swap353: 'My invite link',
  swap354: 'Miner list',
  swap355: 'hold list',
  swap356: 'holding amount',
  swap357: 'Total output',
  swap358: 'Output today',
  swap359: 'Output progress',
  swap360: 'Miner',
  swap361: 'Estimated output',
  swap362: 'Deposit amount',
  swap363: 'The counterparty s account',
  swap364: 'Please enter the other party s account',
  swap365: 'Transfer amount',
  swap366: 'Transfer instructions',
  swap367: 'Internal transfer does not take blocks, no handling fee is deducted, and the account is instantly credited. If it is irreversible, please check the account of the other party carefully. ',
  swap368: 'If you need to transfer to another platform, please use the withdrawal method',
  swap369: 'Exceeding the tradable amount',
  swap370: 'Blind box',
  swap371: 'My assets',
  swap372: 'Bill list',
  swap373: 'Please bind the inviter',
  swap374: 'The total issuing price must be greater than 30USDT',
  swap375: 'The wallet balance is exceeded',
  swap376: 'Posting, please check later',
  swap377: 'New user discount',
  swap378: '----10 USDT HM for first registration',
  swap379: 'Pending order',
  swap380: 'Wallet address',
  swap381: 'Mining',
  swap382: 'Dividend',

  swap383: 'Announcement',
  swap384: 'No announcement',
  swap385: 'bill',
  swap386: 'reward type',
  swap387: 'Invite a generous reward',
  swap388: 'My team',
  swap389: 'Recharge funds',
  swap390: 'Withdraw funds',
  swap391: 'NFT backpack',
  swap392: 'User Feedback',
  swap393: 'About us',
  swap394: 'dashboard',
  swap395: 'mining',
  swap396: 'computing power',
  swap397: 'Retrieve',
  swap398: 'Platform computing power',
  swap399: 'My computing power',
  swap400: 'Team computing power',
  swap401: 'NFT market',
  swap402: 'Price',
  swap403: 'Start',
  swap404: 'My Fragments',
  swap405: 'synthesis',
  swap406: 'Description: Click on the shards, you can start the synthesis when there are five or more pieces, and you can get an NFT or a shard at random',
  swap407: 'Synthesizing',
  swap408: 'gift',
  swap409: 'synthesized successfully',
  swap410: 'Please enter gift address',
  swap411: 'Description: Cannot be revoked after gift',
  swap412: 'OK',
  swap413: 'Purchasing',
  swap414: 'The gift was successful',
  swap415: 'Insufficient number of fragments',
  swap416: 'Song display',
  swap417: 'Display',
  swap418: 'Introduction',
  swap419: 'Partner',
  swap420: 'My Level',
  swap421: 'current level',
  swap422: 'Team performance',
  swap423: 'Standard department',
  swap424: 'Friend Invitation Program',
  swap425: 'Invite friends to join 安盏科技 and earn rich rewards together! If the friends you invite participate in the game, you will get multiple benefits',
  swap426: 'Team size',
  swap427: 'Copy invitation',
  swap428: 'Performance',
  swap429: 'Exchange Quantity',
  swap430: 'Feedback list',
  swap431: 'No feedback yet',
  swap432: 'User',
  swap433: 'Online customer service',
  swap434: 'No reply yet',
  swap435: 'Add pictures (up to three)',
  swap436: 'Please enter a message',
  swap437: 'Feedback',
  swap438: 'Please upload a picture',
  swap439: 'Please enter the feedback content',
  swap440: 'has been held',
  swap441: 'Invite',
  swap442: 'recommended link',
  swap443: 'Please bind the leader',
  swap444: 'Understand',
  swap445: 'Governance',
  swap446: 'guess',
  swap447: 'Please bind the inviter first',
  swap448: 'Cancel successfully',
  swap449: 'type',
  swap450: 'Market',
  swap452: 'Reset',
  swap453: 'fragment',
  swap454: 'Purchase Type',
  swap455: 'Platform announcement',
  swap456: 'Shipping address',
  swap457: 'Introduction to FULL OPEN',
  swap458: 'FULL OPEN is short for FO, which is jointly established by FULL OPEN Foundation and top investment institutions and communities. We are committed to becoming the explorer of the value of WEB3.0 social+meta-universe chain travel, providing the most powerful and abundant social and entertainment data for the global multi-chain ecosystem, jointly exploring the new paradigm of people and organizations, relationships and behaviors in the Web3.0 world, and becoming the social and entertainment infrastructure in the Web3.0 era. FULL OPEN will not only develop conventional social applications, such as chat, voice, video, post, join the community, manage community tools, etc., but also meet the needs of the community, open online conference rooms, and change scenes and personal meeting images at will. DID is the foundation of web3.0 identity. With IPFS+IPNS storage, you can expand and consolidate the multi-chain data of Web3.0 identity to maximize the data value. The first stage is to expand users through digital model nft mining. The second stage is to launch instant messaging, improve social user experience. The second stage is to open game voice, enhance social attributes of the game',
  swap459: 'equity pool',
  swap460: 'Super partner recruitment',
  swap461: 'Member recruitment',
  swap462: 'Super partner s equity',
  swap463: 'Service charge cumulative reward',
  swap464: 'Community cumulative rewards',
  swap465: 'Service charge dividend',
  swap466: 'NFT data collection',
  swap467: 'Chain tour hall',
  swap468: 'Anonymous social',
  swap469: 'AI Cloud Computing',
  swap470: 'Member recruitment plan',
  swap471: 'Tasu Laojiao (9~10 years)',
  swap472: 'Yi Chun Feng Oyster Wine 1062',
  swap473: 'Number of copies purchased',
  swap474: 'Super partner recruitment plan',
  swap475: 'Yichunfeng 519. One Yichunfeng Chongyang wine each',
  swap476: 'Consignee',
  swap477: 'Mobile number',
  swap478: 'Region',
  swap479: 'Region (detailed)',
  swap480: 'Please enter the consignee',
  swap481: 'Please enter mobile phone number',
  swap482: 'Please enter the region',
  swap483: 'Please enter the detailed region',
  swap484: 'Conditions for reaching the next level',
  swap485: 'Please buy first',
  swap486: 'None',
  swap487: 'Today s performance',
  swap488: 'Hall',
  swap489: 'Reward progress',
  swap490: 'Reward',
  swap491: 'Circulation',
  swap492: 'Start time',
  swap493: 'End time',
  swap494: 'My funds',
  swap495: 'My collection',
  swap496: 'Please wait',
  swap497: 'Not eligible for purchase',
  swap498: 'Introduction to the platform',
  swap498: 'Introduction to the platform',
  swap499: 'development',
  swap500: 'NFT',
  swap501: 'Development',
  swap502: 'FULL OPEN is short for FO, which is jointly established by FULL OPEN Foundation and top investment institutions and communities. We are committed to becoming the explorer of the value of WEB3.0 social+metacosmic chain travel, providing the most powerful and abundant social and entertainment data for the global multi-chain ecosystem, jointly exploring the new paradigm of people and organizations, relationships and behaviors in the Web3.0 world, and becoming the social and entertainment infrastructure in the Web3.0 era. ',
  swap503: 'FULL OPEN will not only develop conventional social applications, such as chat, voice, video, post, join the community, manage community tools, etc., but also meet the needs of the community, open online conference rooms, and change scenes and personal conference images at will. DID is the foundation of web3.0 identity. With IPFS+IPNS storage, it can expand and consolidate the multi-chain data of Web3.0 identity to maximize the data value. ',
  swap504: 'The first stage is to expand users through digital model nft mining',
  swap505: 'Phase II,',
  swap506: '1. Launch instant messaging to improve social user experience',
  swap507: '2. Open game voice and enhance game social attributes',
  swap508: 'Phase III,',
  swap509: '1. Online yuan universe chain tour,',
  swap510: '2, the social sector officially integrates the cloud universe, adding more personal images and meeting background options.',
  swap511: 'Performance of the first department',

  swap512: 'Number of points',
  swap513: 'Expand',

  swap514: 'Collapse',
  swap515: 'Baofu Cloud Chain-Guarantee cloud chain (GCC) is committed to building the largest blockchain cloud insurance ecosystem in the world. Individuals at any risk can equally, safely and simply obtain the risk protection of decentralized autonomous organizations, and automatically obtain compensation through smart contracts when insurance accidents occur. Based on GCC protocol, developers can use GCC ',
  swap516: 'Inventory',
  swap517: 'Advanced',
  swap518: 'Points special session',
  swap519: 'Collection details',
  swap520: 'FULL-OPEN is committed to building the world s largest blockchain cloud insurance ecosystem. Individuals at any risk can equally, safely and simply obtain the risk protection of decentralized autonomous organizations, and automatically obtain compensation through smart contracts when insurance accidents occur. Based on GCC protocol, developers can use GCC. ',
  swap521: 'Copyright statement',
  swap522: 'Notice to equity',
  swap523: '1. NFT is a virtual product, not a physical product, and can only be purchased by users in Chinese Mainland who are real name certified and over 18 years old.',
  swap524: '2. Notice on the rights and interests of NFT copyrighted works: non physical objects, only for users in Chinese Mainland who are real name certified and over 18 years old.',
  swap525: '3. Notice on the rights and interests of NFT copyrighted works: non physical objects, only for users in Chinese Mainland who are real name certified and over 18 years old.',
  swap526: 'Purchase instructions',
  swap527: '1. NFT is a virtual product, not a physical product, and can only be purchased by users in Chinese Mainland who are real name certified and over 18 years old.',
  swap528: '2. Before purchasing, please make sure that you are registered in person and that you are authenticated by your ID card, and that your mobile phone number is bound to your registration certificate.',
  swap529: '3. Notice on the rights and interests of NFT copyrighted works: non physical objects, only for users in Chinese Mainland who are real name certified and over 18 years old.',
  swap530: '4. Once sold, no return or exchange is allowed.',
  swap531: 'Open blind box',
  swap532: 'Synthetic activity',
  swap533: 'VIP memory card',
  swap534: 'Exchange',
  swap535: 'Shopping Mall',
  swap536: 'NFT and product sales incentives',
  swap537: 'One, point reward 20%',
  swap538: '(Team network body 20 layers, a total of 1.04 million points 1% reward)',
  swap539: 'Second, 100% and 40% direct referral awards',
  swap540: '(100% for the first line of each point, 40% reward for the second and third lines)',
  swap541: 'Three, 15% wealth award',
  swap542: '(Directly push the second and third lines under the first point, unlimited fission network body rewards, no cap)',
  swap543: 'Fourth, 12% cultivation reward',
  swap544: '(80% reward of direct promotion point wealth award)',
  swap545: 'Five, level reward 13%',
  swap546: '(3% to 13% reward for the teams total performance difference)',
  swap547: 'Six, the total allocation is 100%',
  swap548: '(Deducting 10% manual fee to enter the coin pool, decentralization, positive ratio, no burn, no fund pool, never shut down the network, safe and risk-free)',
  swap549: 'Cast 100U NFT (gift 2.5 times the quota + 100 FO)',
  swap550: 'Cast 500U NFT (gift 5 times the quota + 1000 FO)',
  swap551: 'Cast 1000U NFT (gift 7 times the quota + 3000 FO)',
  swap552: 'Lianchuang dividend pool',
  swap553: 'Added yesterday',
  swap554: 'Network wide service charge',
  swap555: 'New across the network',
  swap556: 'Exchange records',
  swap557: 'My referral address',
  swap558: 'Regional computing power',
  swap559: 'Cell computing power',
  swap560: '安盏科技 section',
  swap561: 'Basic token mining',
  swap562: '安盏科技 Pioneer',
  swap563: 'Art Museum',
  swap564: 'NFT transaction',
  swap565: 'Happy Space',
  swap566: 'Chain game hall',
  swap567: 'Parallel World',
  swap568: 'Metauniverse',
  swap569: 'Personal Center',
swap570: 'Revenue',
swap571: 'Payment quantity',
swap572: 'Total account balance',
swap573: 'Team',
swap574:'transaction price',
   swap575:'pay',
   swap576:'Get',
   swap577:'Transaction tax rate',
   swap578:'Transaction tax',
swap579: 'Current 安盏科技 total output',
swap580: 'Current total destruction',
swap581: 'Computing Power Leasing',
swap582: 'Mining machine computing power',
swap583: 'Mining machine price',
swap584: 'Lease',
swap585: 'Currently no rental permission',
swap586: 'Mining progress',
swap587: 'Current computing power',
swap588: 'Current output value',
swap589: 'Lease time',
swap590: 'Static total output',
swap591: 'Yesterday s static output',
swap592: 'Dynamic total output',
swap593: 'Yesterday s dynamic output',
swap594:'Binding successful',
swap595: "It is already the lowest available computing power",
swap596: "It is already the highest computing power that can be purchased",
swap597: "Contract Address",
swap598: "Destroy contract address",
swap599: 'Payment error',
};

